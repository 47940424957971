html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: modern_erabold, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
canvas {
  margin-top: -1rem;
}

#root {
  background-color: #c9c9c9;
  height: 100vh;
}

.ui {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.ui_container {
  background: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 2px 20px, inset 0 2px 0 #fff;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 376px) {
    padding: 14px;
  }
}

.ui_disclaimer {
  color: #00000094;
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  font-size: 12px;
}

.ui_row {
  margin: 6px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

button {
  flex: 0;
  text-align: center;
  color: #2b60c7;
  font-size: 16px;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.25s;
  transform: scale(1);
  border-radius: 999px;
  padding: 5px 20px 6px;
  border: 2px solid #2b60c7;
  font-weight: 700;
  user-select: none;
}
button:focus {
  outline: none;
  background: #dae3f3;
}

button.active,
button:active {
  background: #2b60c7;
  color: #fff;
}

.ui_play {
  width: 60px;
  height: 60px;
  transition-property: transform;
  transition-duration: 0.25s;
  transform: scale(1);
  border-radius: 60px;
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.ui_slider_container {
  width: 100%;
  margin-left: 16px;
  display: flex;
  height: 60px;
  align-items: center;
  position: relative;
}

.ui_tick_container {
  width: 100%;
  height: 1px;
  position: absolute;
  top: calc(50% - 3px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ui_tick {
  background: black;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
.ui_slider_legend {
  width: 100%;
  height: 1px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;
  font-size: 13px;
  font-weight: 500;
  padding-top: 1rem;
}

input[type="range"] {
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent;
  cursor: pointer;
  position: absolute;
  height: 10px;
}
input[type="range"],
input[type="range"]:focus,
input[type="range"]:active,
input[type="range"]::-moz-focus-inner,
input[type="range"]:-moz-focusring {
  border: 0;
  outline: none;
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0;
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 1px;
  background: black;
}
input[type="range"]::-moz-range-track {
  height: 1px;
  background: black;
}
input[type="range"]::-ms-track {
  height: 1px;
  background: black;
}
input[type="range"]::-ms-fill-lower {
  display: none;
}
input[type="range"]::-webkit-slider-thumb:focus,
input[type="range"]::-webkit-slider-thumb {
  transform: translateY(-10px);
  width: 22px;
  height: 22px;
  border-radius: 999px;
  background-color: #2b60c7;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2), inset 0 1px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid #234ea1;
}

input[type="range"]::-moz-range-thumb {
  width: 22px;
  height: 22px;
  border-radius: 999px;
  background-color: #2b60c7;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2), inset 0 1px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid #234ea1;
}
input[type="range"]::-moz-range-thumb,
input[type="range"]::-ms-thumb {
  width: 22px;
  height: 22px;
  border-radius: 999px;
  background-color: #2b60c7;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2), inset 0 1px 0px rgba(255, 255, 255, 0.2);
  border: 1px solid #234ea1;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~
styles for the loading screen
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.progressContainer {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loader {
  overflow: hidden;
  position: relative;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 42px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #222;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

/*~~~~~~~~~~~~~~~~~~~
  Error messages
~~~~~~~~~~~~~~~~~~~~*/
.error {
  background: white;
  padding: .5rem;
  border-radius: 10px;
  margin-bottom: 6px;
}
